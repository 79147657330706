.navbarContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  height: 100vh;
  width: 80px;
  overflow: hidden;
  z-index: 1000;
  background-color: #f7f7f8;
  box-shadow: 0px 0px 10px -2px #000;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
  margin-bottom: 30px;

  img {
    height: 60px;
    width: 55px;
  }
}

.storeIconsContainer {
  display: flex;
  flex-direction: column;
}

.storeIconItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #153c5d;
  height: 86px;
  padding: 10px 0;
  transition: 0.3s;
  user-select: none;

  svg {
    width: 55px;
    margin-bottom: 10px;
  }

  span {
    font-size: 11px;
    font-weight: 900;
  }

  &.inactive {
    cursor: default;
    // opacity: .5;
  }

  &.active:hover {
    // background-color: #e7e7e7;
    background-color: #FFF9ED;
  }

  &.hovered {
    background-color: #FFF9ED;
  }
}

.to-bottom {
  position: absolute;
  width: 100%;
  bottom: 1px;
}